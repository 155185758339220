






































































































































































import Vue, { PropType } from "vue";
import { ViewCandidatesOptions } from "@/interfaces/recruiter/admin/all_candidates";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  ACTION_CANDIDATE,
  AL_AIN_USER_FILTER,
  CRITERIA_FILTER,
  GET_ALL_CANDIDATES
} from "@/store/modules/recruiter_admin/constants";
import { TranslateResult } from "vue-i18n";
import AutoCompleteUserAndEidSearch from "@/components/shared/AutoCompleteUserAndEidSearch.vue";
import { SearchUserObject } from "@/interfaces/shared/auto_complete_user_search";
import DeleteItem from "@/components/shared/DeleteItem.vue";

export default Vue.extend({
  name: "AllCandidatesHeader",
  components: {
    AutoCompleteUserAndEidSearch,
    DeleteItem
  },
  data() {
    return {
      loading: false,
      is_approve_all: false,
      dialog_box: false,
      // al_ain_users_items: [
      //   {
      //     text: "Yes",
      //     value: true
      //   },
      //   {
      //     text: "No",
      //     value: false
      //   }
      // ],
      criteria_filter: [
        { text: "Al Ain User", value: "al_ain_users" },
        { text: "Auto Approved", value: "auto_approved" },
        { text: "Wifi Availability", value: "wifi_available" },
        { text: "Laptop Available", value: "laptop_available" },
        { text: "Full Time Available", value: "full_time_available" }
      ],
      filter_overlay: false,
      delete_icon: require("@/assets/icons/bold/reset.svg"),
      filter_icon: require("@/assets/icons/bold/filter.svg"),
      filters: [
        { label: this.$t("auth.name"), value: true },
        { label: this.$t("auth.eid-number"), value: false },
        { label: this.$t("auth.email"), value: false }
      ],
      selected_fitler: this.$t("auth.name") as TranslateResult
    };
  },
  computed: {
    ...mapGetters("recruiter_admin", {
      get_all_candidates: GET_ALL_CANDIDATES,
      get_al_ain_users_filter: AL_AIN_USER_FILTER,
      get_criteria_filter: CRITERIA_FILTER
    }),
    ViewCandidatesOptions() {
      return ViewCandidatesOptions;
    },
    al_ain_users_filter: {
      get(): boolean {
        return this.get_al_ain_users_filter;
      },
      set(value: boolean): void {
        this.set_al_ain_filter(value);
      }
    },
    value: {
      get(): boolean {
        return this.get_criteria_filter;
      },
      set(value: boolean): void {
        this.set_criteria_filter(value);
      }
    }
  },
  props: {
    filter: {
      type: String as PropType<ViewCandidatesOptions>,
      required: true
    }
  },
  methods: {
    ...mapActions("recruiter_admin", {
      action_candidate: ACTION_CANDIDATE
    }),
    ...mapMutations("recruiter_admin", {
      set_al_ain_filter: AL_AIN_USER_FILTER
    }),
    ...mapMutations("recruiter_admin", {
      set_criteria_filter: CRITERIA_FILTER
    }),
    async dialog_action(value: boolean) {
      this.is_approve_all = value;
      this.dialog_box = false;
      this.$emit("approve_all", value);
    },
    async approve_submit() {
      this.dialog_box = true;
    },

    toggle_filter_overlay() {
      this.filter_overlay = !this.filter_overlay;
    },
    filter_change(ind: number, filter: TranslateResult) {
      this.selected_fitler = filter;
      this.filters = this.filters.map((filter, index) => {
        return { ...filter, value: ind === index };
      });
      this.filter_overlay = false;
    },
    searched_user(users: SearchUserObject[]) {
      if (users) {
        this.$emit(
          "searched_user",
          users.map((user) => user.id)
        );
      }
    }
  }
});
